@font-face {
    font-family: 'Sota';
    src: url('./ttf/Sora.ttf');
    font-weight: normal;
}

.paginaDefault {
    font-family: 'Sora', serif;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #FFFFFF;
    }

    a:hover {
        color: #EEEEEE;
    }

    .titulo {
        font-size: 3em;
        color: #FFFFFF;
        margin-bottom: 1rem;
    }

    .rojo {
        color: #df382c;
    }
}
