@import '../node_modules/bootstrap/scss/bootstrap';

body {
    user-select: none;
}

.App {
    min-height: 100vh;
    min-width: 100vw;
}
