@font-face {
    font-family: 'Harlow';
    src: url('../ttf/Harlow.ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'High Tower';
    src: url('../ttf/HighTower.ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'High Tower Light';
    src: url('../ttf/HighTower-Light.ttf');
    font-weight: normal;
}

.st-cuenca {
    $colortexto: #000000;
    $colorfondo: #ffffff;
    $fuente-normal: 'High Tower', serif;
    $fuente-light: 'High Tower Light', serif;
    $fuente-titulos: 'Harlow', serif;

    a {
        color: $colortexto;
    }

    .logoCliente {
        max-height: 20rem;
        max-width: 80vw;
    }

    .nombreCliente {
        text-align: center;
        font-size: 2em;
    }

    .infoCliente {
        text-align: center;
        text-transform: uppercase;
    }

    .fondoMenu {
        font-family: $fuente-normal;
        background-color: $colorfondo;
        color: $colortexto;
    }

    .enlacesMenu {
        text-align: center;
    }

    .enlacesMenu a {
        color: $colortexto;
    }

    .enlacesMenu a:hover {
        color: mix($colortexto, $colorfondo, 75%)
    }

    .tituloGrupo {
        font-family: $fuente-titulos;
        font-size: 2em;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @media (max-width: 576px) {
        .tituloGrupo {
            font-size: 1.5em;
        }
    }

    .tituloCategoria {
        background-color: $colortexto;
        color: $colorfondo;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.25rem;
        text-align: center;
        text-transform: uppercase;
    }

    .productosCategoria {
        font-size: 1.05em;
    }

    @media (max-width: 576px) {
        .productosCategoria {
            font-size: 0.95em;
        }
    }

    .cuadroProveedor {
        padding: 0.5rem;
        margin-bottom: 1rem;
        border: 1px solid #000000;
        text-transform: uppercase;
        font-size: 0.75em;
    }

    .tituloProveedor {
        font-size: 1.35em;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .logoProveedor {
        max-width: 10rem;
        max-height: 10rem;
    }

    .lineaPrecios {
        margin-left: 1rem;
        margin-right: 1rem;
        border-bottom: 1px dotted #000000;
    }

    @media (max-width: 576px) {
        .lineaPrecios {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            min-width: 15px;
        }
    }

    .descripcionProducto {
        font-size: 0.75em;
        padding-left: 0.75rem;
        margin-bottom: 0.75rem;
        font-family: $fuente-light;
    }

    .renglonCerveza {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $colortexto;
        min-height: 6rem;
    }

    @media (max-width: 576px) {
        .renglonCerveza {
            font-size: 0.85em;
        }
    }

    .tituloCerveza {
        font-size: 1.5em;
    }

    .proveedorCerveza:before {
        content: '/'
    }

    .proveedorCerveza {
        font-size: 0.85em;
        font-family: $fuente-light;
    }

    .descripcionCerveza {
        font-size: 0.9em;
        padding-left: 0.75rem;
        font-family: $fuente-light;
    }

    .precioCerveza {
        font-family: $fuente-light;
    }

    .footer {
        margin-top: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: uppercase;
    }

    .poweredBy {
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.65em;
    }
}